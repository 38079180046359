import React, { useState, useEffect, useRef } from "react";

const FaqsCard = ({ faqsList, idx }) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(state ? "0px" : `${answerElH + 20}px`);
  };

  return (
    <div
      className={`group border border-gray-300 hover:border-[#626162] hover:bg-[#f8f8f1] transition-colors ease-out duration-300 mt-5 overflow-hidden rounded-xl ${
        state ? "bg-[#f8f8f1]" : "bg-[#262626]"
      }`}
      key={idx}
      onClick={handleOpenAnswer}>
      <h4
        className={`cursor-pointer p-5 flex items-center justify-between text-lg sm:text-xl ${
          state ? "text-gray-800" : "text-gray-100"
        } hover:text-gray-800 font-medium`}>
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}>
        <div>
          <p className="text-gray-800 px-5 text-sm sm:text-base">
            {faqsList.a}
          </p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const faqsList = [
    {
      q: "What are responsive templates?",
      a: "Responsive templates are pre-designed formats used for creating various digital assets like website layouts, press release layouts, email newsletter designs, and marketing materials.",
    },
    {
      q: "How can templates benefit my brand?",
      a: "Templates can provide consistency, efficiency, and professionalism across your marketing materials and communications.",
    },
    {
      q: "Are templates customizable?",
      a: "Yes, we offer extensive customization options for every template layout. Modify colors, fonts, content, and visuals to match your brand identity.",
    },
    {
      q: "Are these templates optimized for all devices?",
      a: "Yes, our templates are fully optimized to perform well and look great on various devices, from desktops to mobile phones.",
    },
    {
      q: "Are these templates free?",
      a: "Yes. CronMedia's free version allows you to make 10 translations a month. Upgrade to Pro for unlimited premium templates.",
    },
  ];

  return (
    <section
      ref={sectionRef}
      id="faq"
      className="leading-relaxed pb-14 my-10 px-5 sm:px-10 xl:px-0 max-w-6xl mx-auto">
      <div className="space-y-3 text-center">
        <h1
          className={`text-3xl sm:text-4xl md:text-5xl text-white font-medium ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200`}>
          We’ve got you covered
        </h1>
      </div>
      <div
        className={`mt-8 text-left max-w-screen-lg mx-auto ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200 delay-150`}>
        {faqsList.map((item, idx) => (
          <FaqsCard key={idx} idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;
