import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";

const CaseStudies = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center px-4">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Skilcamp Case Study
          </h1>
          <p
            className={`text-lg max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            Explore how Skilcamp redefined the learning experience with
            innovative e-learning solutions. This case study dives deep into our
            process, challenges, and successful outcomes, highlighting the power
            of engaging, user-centric design in the digital education space.
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center h-fit">
        <div
          className="relative w-[70%] md:w-[60%] lg:w-[40%] aspect-video shadow-2xl shadow-blue-400 ring-8 rounded-lg"
          style={{
            transform:
              "perspective(542px) rotate(22deg) rotateX(8.5deg) rotateY(-28deg) skewX(0.6deg) skewY(-16.3deg)",
            transformOrigin: "100% 50% 0px", translate: "-3em"
          }}>
          <iframe
            src="https://skilcamp.com/"
            title="Skillcamp"
            className="w-full h-full rounded-lg shadow-lg border border-gray-300"
            loading="lazy"
            fetchpriority="auto"
            referrerpolicy="no-referrer"
            sandbox="allow-same-origin allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"></iframe>
        </div>
      </div>

      <div className=" text-white py-10 px-5 md:px-20 lg:px-40">
        <h2 className="text-3xl font-bold mb-6">What’s New</h2>

        {/* Section 1 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-3">
            Transforming Business Functions with SaaS
          </h3>
          <p className="text-gray-300">
            In today’s digital age, businesses face the constant challenge of
            enhancing efficiency, streamlining processes, and driving growth.
            SaaS has emerged as a game-changing solution, enabling organizations
            to unlock their true potential by harnessing the power of
            cloud-based applications. In this article, we will delve into the
            world of SaaS, exploring its benefits, implementation strategies,
            and the impact it can have on businesses across industries.
          </p>
        </div>

        {/* Section 2 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-3">
            Benefits of SaaS Adoption
          </h3>
          <p className="text-gray-300">
            SaaS empowers organizations to say goodbye to large upfront software
            investments. With subscription-based pricing models, businesses can
            scale their software usage as needed, paying only for the features
            and resources they require. This flexibility enables cost savings
            while providing the ability to adapt quickly to changing business
            demands.
          </p>
        </div>

        {/* Benefits List */}
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-3">List of Benefits</h4>
          <ul className="list-disc list-inside space-y-2 text-gray-300">
            <li>Cost Savings and Scalability</li>
            <li>Seamless Updates and Maintenance</li>
            <li>Accessibility and Collaboration</li>
            <li>SaaS Implementation Strategies</li>
            <li>Identify Business Needs</li>
          </ul>
        </div>

        {/* Section 3 */}
        <div className="mb-8">
          <p className="text-gray-300">
            As technology continues to evolve, the SaaS landscape is poised for
            even greater advancements. The integration of artificial
            intelligence, machine learning, and big data analytics within SaaS
            applications will further empower businesses to make data-driven
            decisions, automate processes, and deliver enhanced user
            experiences.
          </p>
        </div>

        {/* Section 4 */}
        <div>
          <p className="text-gray-300">
            The cloud-based nature of SaaS allows for easy access to
            applications from any location and device with an internet
            connection. This accessibility promotes collaboration among teams,
            regardless of geographical boundaries, enhancing productivity and
            efficiency across the organization.
          </p>
        </div>

        {/* Conclusion */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold">Conclusion</h3>
          <p className="text-gray-300 mt-3">
            Software as a Service has revolutionized the way businesses operate,
            providing unprecedented flexibility, cost savings, and scalability.
            By embracing SaaS solutions, organizations can streamline
            operations, boost productivity, and stay ahead of the competition in
            today’s dynamic business landscape. As we look to the future, SaaS
            will continue to be a catalyst for innovation.
          </p>
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
