import React from "react";
import { useInView } from "react-intersection-observer";

const AnimatedVideo = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.1, // Trigger animation when 10% of the component is visible
  });

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ease-out ${
        inView
          ? "translate-y-0 scale-100 opacity-100"
          : "translate-y-10 scale-50 opacity-0"
      }`}>
      <video className="w-fit h-fit rounded-3xl" autoPlay muted loop>
        <source
          src="https://cronbay-tech.com/image/Cronbay_Web_Banner.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AnimatedVideo;