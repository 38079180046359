import React from "react";

const testimonials = [
  {
    quote:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    name: "Md Shafquallah",
    role: "Cypera Digital",
    image: "https://via.placeholder.com/50",
  },
  {
    quote:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    name: "Md Shafquallah",
    role: "Cypera Digital",
    image: "https://via.placeholder.com/50",
  },
  {
    quote:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    name: "Md Shafquallah",
    role: "Cypera Digital",
    image: "https://via.placeholder.com/50",
  },
  {
    quote:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    name: "Md Shafquallah",
    role: "Cypera Digital",
    image: "https://via.placeholder.com/50",
  },
];

const TestimonialCard = ({ quote, name, role, image }) => (
  <div className="flex flex-col bg-white rounded-3xl p-6 shadow-lg w-72 sm:w-80 flex-shrink-0 mx-4">
    <p className="text-gray-600 text-base sm:text-lg mb-6 text-wrap">
      "{quote}"
    </p>
    <div className="flex items-center">
      <img
        src={image}
        alt={name}
        className="w-12 h-12 rounded-full border-2 border-blue-500"
      />
      <div className="ml-4">
        <h4 className="font-bold text-blue-600">{name}</h4>
        <p className="text-gray-500">{role}</p>
      </div>
    </div>
  </div>
);

const Testimonials = () => {
  return (
    <div className="bg-blue-800 text-white py-12">
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">
        Don’t take our word for it
      </h2>
      <div className="relative mb-10 flex overflow-hidden">
        {/* Marquee container */}
        <div className="py-2 animate-marquee whitespace-nowrap flex">
          {testimonials.map((testimonial, index) => (
            <span className="inline-block" key={index}>
              <TestimonialCard
                quote={testimonial.quote}
                name={testimonial.name}
                role={testimonial.role}
                image={testimonial.image}
              />
            </span>
          ))}
        </div>
        <div className="py-2 animate-marquee2 whitespace-nowrap absolute top-0 flex">
          {testimonials.map((testimonial, index) => (
            <span className="inline-block" key={index}>
              <TestimonialCard
                quote={testimonial.quote}
                name={testimonial.name}
                role={testimonial.role}
                image={testimonial.image}
              />
            </span>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <button className="p-[16px] px-8 bg-gradient-to-r from-white to-white text-[#1C65f0] hover:text-white hover:from-blue-500 hover:to-blue-500 text-base font-semibold rounded-full">
          More stories
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
