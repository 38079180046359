import React, { useState, useEffect, useRef } from "react";
import ecom from "../assets/ecom.svg";
import ed from "../assets/ed.svg";
import health from "../assets/health.svg";
import re from "../assets/re.svg";
import retail from "../assets/retail.svg";
import tech from "../assets/tech.svg";

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="bg-gradient-to-b from-[#141821] to-[#131213] hover:bg-gradient-to-br hover:from-[#1a5fe4] hover:to-[#020639] text-white rounded-3xl p-6 shadow-md flex flex-col items-center justify-center text-center transition duration-300 ease-in-out border border-gray-700">
      <div className="w-16 h-16 bg-[#1a5fe4] rounded-full flex items-center justify-center mb-4">
        <img src={icon} alt={title} className="w-8 h-8" />
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-400 text-sm">{description}</p>
    </div>
  );
};

const Features = () => {
   const [isVisible, setIsVisible] = useState(false);
   const sectionRef = useRef(null);

   useEffect(() => {
     const observer = new IntersectionObserver(
       ([entry]) => {
         if (entry.isIntersecting) {
           setIsVisible(true);
         }
       },
       { threshold: 0.1 } 
     );

     const currentRef = sectionRef.current;
     if (currentRef) {
       observer.observe(currentRef);
     }

     return () => {
       if (currentRef) {
         observer.unobserve(currentRef);
       }
     };
   }, []);
  
  // Card Data
  const cardData = [
    {
      title: "E-commerce",
      description:
        "Boost conversions and enhance customer experience with tailored e-commerce strategies.",
      icon: ecom,
    },
    {
      title: "Healthcare",
      description:
        "Deliver trust and engagement with impactful healthcare marketing strategies.",
      icon: health,
    },
    {
      title: "Tech",
      description:
        "Amplify product visibility and user adoption with cutting-edge digital solutions.",
      icon: tech,
    },
    {
      title: "Real Estate",
      description:
        "Market properties effectively with immersive experiences and data-driven strategies.",
      icon: re,
    },
    {
      title: "Education",
      description:
        "Engage learners and promote educational programs with digital expertise.",
      icon: ed,
    },
    {
      title: "Retail marketing",
      description:
        "Drive traffic and loyalty with seamless omnichannel retail strategies.",
      icon: retail,
    },
  ];

  return (
    <div
      ref={sectionRef}
      className="text-white text-center flex flex-col items-center justify-center px-4 py-12 max-w-6xl mx-auto">
      {/* Section Heading */}
      <h2
        className={`text-4xl font-bold mb-4 ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200`}>
        Powerful features tailored to your needs
      </h2>
      <p
        className={`text-gray-400 text-lg mb-10 text-center ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-4"
        } transition-all ease-in duration-200 delay-100`}>
        Gain valuable data-driven insights into target markets worldwide.
      </p>

      {/* Grid Section */}
      <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl px-4 ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-4"
        } transition-all ease-in duration-200 delay-150`}>
        {cardData.map((card, index) => (
          <FeatureCard
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;
