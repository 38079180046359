import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";

const BlogCard = ({ image, category, title }) => {
  return (
    <div className="bg-[#1a1a1a] rounded-xl overflow-hidden shadow-lg">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <span className="inline-block bg-blue-600 text-white text-xs px-2 py-1 rounded-full mb-2">
          {category}
        </span>
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

const BlogsPage = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the component is visible
      );

      const currentRef = sectionRef.current;
      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    const blogData = [
      {
        image: "https://via.placeholder.com/500X500",
        category: "Articles",
        title: "What is CAC and how do I calculate it?",
      },
      {
        image: "https://via.placeholder.com/500X500",
        category: "Case Studies",
        title:
          "How you can use recurring revenue financing for faster growth without dilution",
      },
      {
        image: "https://via.placeholder.com/500X500",
        category: "Funding",
        title: "Announcing Slang.ai's $20M in funding",
      },
      {
        image: "https://via.placeholder.com/500X500",
        category: "Articles",
        title: "What is CAC and how do I calculate it?",
      },
      {
        image: "https://via.placeholder.com/500X500",
        category: "Case Studies",
        title:
          "How you can use recurring revenue financing for faster growth without dilution",
      },
      {
        image: "https://via.placeholder.com/500X500",
        category: "Funding",
        title: "Announcing Slang.ai's $20M in funding",
      },
    ];

    return (
      <>
        <div ref={sectionRef} className="border-b border-gray-600 text-center">
          <GradientCircleAnimation
            numberOfCircles={
              window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
            }
            size={
              window.innerWidth < 640
                ? 200
                : window.innerWidth < 1024
                ? 300
                : 400
            }
            className="absolute top-0 overflow-hidden"
          />
          <div className="flex flex-col gap-4 my-12 mt-36">
            <h1
              className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
                isVisible
                  ? "opacity-100 scale-100 translate-y-0"
                  : "opacity-0 scale-95 translate-y-10"
              } transition-all ease-in duration-200`}>
              Learn and grow
            </h1>
            <p
              className={`text-lg max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
                isVisible
                  ? "opacity-100 scale-100 translate-y-0"
                  : "opacity-0 scale-95 translate-y-4"
              } transition-all ease-in duration-200 delay-100`}>
              Here are all our essential tips to empower you to embark on your
              entrepreneurial journey.
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-t from-current to-black">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto px-4 py-20">
            {blogData.map((blog, index) => (
              <BlogCard
                key={index}
                image={blog.image}
                category={blog.category}
                title={blog.title}
              />
            ))}
          </div>
        </div>
      </>
    );
};

export default BlogsPage;
