import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import Faq from "./Faq";
// import Map from "./Map";
import Discover from "./Discover";
import axios from "axios";


const Career = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    twitterURL: "",
    linkedInURL: "",
    letter: "",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);



// form Submit
const handleSubmit = (e) => {
  e.preventDefault();
  axios
    .post("https://cyperadigital.com/Backend/career.php", formData)
    .then(response => {
      alert(response.data.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        twitterURL: "",
        linkedInURL: "",
        letter: "",
      }); // Reset form
    })
    .catch(error => console.error("Error inserting data:", error));
};


  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Join Our Team
          </h1>
          <p
            className={`text-lg max-w-3xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            Have a question, suggestion, or just want to learn more about our
            cutting-edge digital marketing solutions? Get in touch!
          </p>
        </div>
      </div>

      <div class="min-h-screen bg-gradient-to-r from-black via-gray-900 to-gray-800 text-white py-10">
        <div class="max-w-5xl mx-auto px-4">
          <form onSubmit={handleSubmit} class="space-y-6 bg-transparent p-8 rounded-lg ">
             {/* <!-- Full Name --> */}
             <div>
                <label class="block text-sm font-medium mb-2" for="full-name">
                  Full Name *
                </label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="Your Full Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500  px-4 py-6"
                />
              </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
           
              {/* <!-- Email --> */}
              <div>
                <label class="block text-sm font-medium mb-2" for="email">
                  Email *
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
                />
              </div>
              {/* <!-- Phone Number --> */}
              <div>
                <label class="block text-sm font-medium mb-2" for="phone">
                  Phone Number *
                </label>
                <input
                  id="phone"
                  type="text"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }

                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500  px-4 py-6"
                />
              </div>
           
            </div>

               {/* <!-- Position apply for --> */}
               <div>
                <label class="block text-sm font-medium mb-2 pt-6" for="company">
                  Position Applying For* 
                </label>
                <select
                  id="company"
                  type="text"
                  placeholder="Company Name"
                  value={formData.position}
                  onChange={(e) =>
                    setFormData({ ...formData, position: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500  px-4 py-6"
                >
                    <option value="">Select...</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Digital Marketing">Digit Marketing</option>
                    <option value="UX/UI Designer">UX/UI Designer</option>
                    <option value="Content Creator">Content Creator</option>
                </select>
              </div>

            {/* <!--Twitter URL --> */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6">
                Twitter Profile URL
              </label>
              <div class="grid grid-cols-1 md:grid-cols-1 gap-4">
                <label class="flex items-center">
                  <input
                    type="text"
                   placeholder="Your X(Twitter) profile URL"
                   value={formData.twitterURL}
                   onChange={(e) =>
                    setFormData({ ...formData, twitterURL: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
                  />
                 
                </label>
                
              </div>
            </div>

            {/* <!-- LinkedIn Profile URL --> */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6">
                LinkedIn Profile URL
              </label>
              <div class="grid grid-cols-1 md:grid-cols-1 gap-4">
                <label class="flex items-center">
                  <input
                    type="text"
                   placeholder="Your LinkedIn profile URL"
                   value={formData.linkedInURL}
                   onChange={(e) =>
                    setFormData({ ...formData, linkedInURL: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500  px-4 py-6"
                  />
                 
                </label>
                
              </div>
            </div>

            {/* <!-- Intension Letter --> */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6">
               Intention Letter
              </label>
              <div class="grid grid-cols-1 md:grid-cols-1 gap-4">
                <label class="flex items-center">
                  <textarea
                    type="text"
                    rows="5"
                   placeholder="Write in a few senttences why do you want to apply for this job..."
                   value={formData.letter}
                   onChange={(e) =>
                    setFormData({ ...formData, letter: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
                  ></textarea>
                 
                </label>
                
              </div>
            </div>

            {/* <!-- Submit Button --> */}
            <div class="text-center">
              <div class="group relative">
                <button
                  type="submit"
                  class="bg-white text-black text-lg px-8 hover:px-10 py-4 rounded-full font-semibold hover:bg-gray-100 shadow-lg"
                >
                  Submit
                  <span class="absolute px-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100  transition-all duration-300">
                  &#8594;
                </span>
                </button>

              
              </div>
            </div>
          </form>
        </div>
          </div>
          
  

          <Faq />

          <Discover />
    </>
  );
};

export default Career;
