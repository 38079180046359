import React, { useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import white_logo from "../assets/Logo_White_Cypera.svg";
import logo from "../assets/Main_logo_Cypera.svg";
import { IoMenu } from "react-icons/io5";
import { FaX } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav
      className={`bg-white/25 backdrop-blur-sm p-4 transition-all ease-in-out duration-200 ${
        isMenuOpen ? "rounded-3xl h-[262px]" : "rounded-[100px] h-[68px]"
      } border-b-2 border-gray-300 w-full overflow-hidden z-50`}>
      <div className="flex justify-between items-center text-white">
        <div className="items-center hidden md:block">
          <Link to="/">
            <img
              src={white_logo}
              alt="Cypera Logo"
              className={`h-8 mr-2 ${isHovered ? "hidden" : ""}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
            <img
              src={logo}
              alt="Cypera Logo"
              className={`h-8 mr-2 ${isHovered ? "" : "hidden"}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </Link>
        </div>
        <ul className="space-x-6 hidden md:flex">
          <li>
            <Link
              to="/about"
              // onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="hover:text-orange-500 hover:underline">
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/case-studies"
              // onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="hover:text-orange-500 hover:underline">
              Case Studies
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              // onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="hover:text-orange-500 hover:underline">
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              // onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="hover:text-orange-500 hover:underline">
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              // onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="hover:text-orange-500 hover:underline">
              Blog
            </Link>
          </li>
        </ul>
        <Link
          to="/contact"
          className="gap-2 bg-gradient-to-r from-[#1a5fe4] to-[#020639] hover:from-[#FFF] hover:to-[#FFF] text-white hover:text-blue-700 font-bold py-2 text-[16px] px-3 rounded-full  hidden md:flex">
          <button className="">Contact us</button>
          <GoArrowUpRight size={20} className=" h-fit my-auto"/>
        </Link>
      </div>

      <div>
        <div className="md:hidden">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img
                src={white_logo}
                alt="Cypera Logo"
                className={`h-8 mr-2 ${isHovered ? "hidden" : ""}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
              <img
                src={logo}
                alt="Cypera Logo"
                className={`h-8 mr-2 ${isHovered ? "" : "hidden"}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            </Link>

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white focus:outline-none">
              {isMenuOpen ? <FaX size={32} /> : <IoMenu size={35} />}
            </button>
          </div>

          <div className={`${isMenuOpen ? "block" : "hidden"}`}>
            <ul className="text-white pt-4">
              <li>
                <Link
                  to="/about"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="hover:text-orange-500 hover:underline">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/case-studies"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="hover:text-orange-500 hover:underline">
                  Case Studies
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="hover:text-orange-500 hover:underline">
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="hover:text-orange-500 hover:underline">
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="hover:text-orange-500 hover:underline">
                  Blog
                </Link>
              </li>
            </ul>
            <button className="bg-blue-700 text-white py-3 px-4 rounded-full hover:bg-blue-800 md:hidden block mt-4 w-full ">
              Contact us
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
