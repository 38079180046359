import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import Features from "./Features";
import AnimatedVideo from "./AnimatedVideo";
import Map from "./Map";
import Faq from "./Faq";
import Testimonials from "./Testimonials";

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center px-4">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Who we are and why we're doing this
          </h1>
          <p
            className={`text-lg max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            Learn about our mission, our role in the tech landscape, and the
            values that drive us to innovate and empower businesses.
          </p>
        </div>
      </div>

      <Features />

      <div className="flex items-center justify-center flex-col space-y-20">
        <AnimatedVideo />
          </div>
          
          <Map />

          <Faq />

          <Testimonials />
    </>
  );
};

export default About;
