import React from "react";
import { Link } from "react-router-dom";

const Interactive = () => {
  // const [positions, setPositions] = useState({
  //   performance: { x: 0, y: 0 },
  //   evolution: { x: 0, y: 0 },
  //   speed: { x: 0, y: 0 },
  //   awareness: { x: 0, y: 0 },
  //   top: { x: 0, y: 0 },
  //   growth: { x: 0, y: 0 },
  //   people: { x: 0, y: 0 },
  //   symbol: { x: 0, y: 0 },
  // });

  // Track which element is being dragged
  // const [draggingElement, setDraggingElement] = useState(null);
  // const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });

  // Handle mouse down event (start dragging)
  // const handleMouseDown = (element) => (event) => {
  //   event.preventDefault();
  //   setDraggingElement(element);
  //   setLastPosition({ x: event.clientX, y: event.clientY });
  // };
  // // Handle mouse down event (start dragging)
  // const handleMouseDown = (element) => (event) => {
  //   event.preventDefault();
  //   setDraggingElement(element);
  //   setLastPosition({ x: event.clientX, y: event.clientY });
  // };

  // Handle mouse move event (while dragging)
  // const handleMouseMove = (event) => {
  //   if (!draggingElement) return;

  //   const deltaX = event.clientX - lastPosition.x;
  //   const deltaY = event.clientY - lastPosition.y;

  //   // Update position based on which element is being dragged
  //   setPositions((prevPositions) => ({
  //     ...prevPositions,
  //     [draggingElement]: {
  //       x: prevPositions[draggingElement].x + deltaX,
  //       y: prevPositions[draggingElement].y + deltaY,
  //     },
  //   }));

    // Update the last position for smooth dragging
  //   setLastPosition({ x: event.clientX, y: event.clientY });
  // };

  // Handle mouse up event (stop dragging)
  // const handleMouseUp = () => {
  //   setDraggingElement(null);
  // };

  // Reset position for specific element
  // const resetPosition = (element) => () => {
  //   setPositions((prevPositions) => ({
  //     ...prevPositions,
  //     [element]: { x: 0, y: 0 },
  //   }));
  // };
  return (
    <div className="md:px-[80px] px-8 md:py-[60px] mx-auto">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-20">
        <div className="md:w-7/12 ">
          <h1 className="text-[#fffcf5] text-4xl md:text-6xl font-medium">
            Empowering Businesses Globally
          </h1>
          <p className="text-[#9CA3BD] py-8 text-lg">
            With over 6 years of success, we’ve partnered with top brands to
            fuel their growth, drive innovation, and deliver exceptional results
            across industries.
          </p>
          <Link
            to="/contact"
            className="p-[16px]   px-8 bg-gradient-to-r from-[#FFF] to-[#FFF] text-[#1C65f0] hover:text-white hover:from-[#020639] hover:to-[#1a5fe4] text-base font-semibold rounded-3xl">
            Expert Solution
          </Link>
        </div>
        <div className="flex flex-col gap-32 justify-between rounded-3xl shadow-customInset border border-[rgba(255, 255, 255, 0.3)] shadow-inner py-6">
          <div className="text-blue-400 text-3xl font-bold text-center ">
            6+ years in business
          </div>

          <div
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
            // onMouseLeave={handleMouseUp}
            className="flex md:mb-[-14px]  cursor-pointer md:text-base text-sm"
          >
            {/* Draggable Elements */}
            <div className="flex flex-col justify-end gap-2 items-end">
              {/* Performance */}
              <div
                // draggable="true"
                // style={{
                //   transform: `translate(${positions.performance.x}px, ${positions.performance.y}px) rotate(163deg)`,
                //   transition:
                //     draggingElement === "performance"
                //       ? "none"
                //       : "transform 0.3s ease",
                // }}
                // onMouseDown={handleMouseDown("performance")}
                // onMouseUp={resetPosition("performance")}
                className=" rotate-[-195deg] hover:rotate-[360deg] bg-black text-white p-2 md:px-6 md:py-3 rounded-full transition-transform duration-500 shadow-md"
              >
                Performance
              </div>

              {/* Evolution */}
              <div
                // draggable="true"
                // style={{
                //   transform: `translate(${positions.evolution.x}px, ${positions.evolution.y}px) `,
                //   transition:
                //     draggingElement === "evolution"
                //       ? "none"
                //       : "transform 0.3s ease",
                // }}
                // onMouseDown={handleMouseDown("evolution")}
                // onMouseUp={resetPosition("evolution")}
                className="hover:scale-110 bg-black text-white p-2 md:px-6 md:py-3 rounded-full shadow-md"
              >
                Evolution
              </div>
            </div>

            {/* Other Static Elements */}
            <div className="flex flex-col gap-2 items-center">
              {/* Speed */}
              <div
              // draggable="true"
              // style={{
              //   transform: `translate(${positions.speed.x}px, ${positions.speed.y}px) `,
              //   transition: draggingElement === "speed" ? "none" : "transform 0.3s ease",
              // }}
              // onMouseDown={handleMouseDown("speed")}
              // onMouseUp={resetPosition("speed")}
              className="transform rotate-[210deg] hover:rotate-[360deg] bg-black mb-2 text-white p-2 md:px-6 md:py-3 rounded-full shadow-md transition-transform duration-500">
                Speed
              </div>

              {/* Awareness */}
              <div 
              //  draggable="true"
              //  style={{
              //    transform: `translate(${positions.awareness.x}px, ${positions.awareness.y}px) `,
              //    transition: draggingElement === "awareness" ? "none" : "transform 0.3s ease",
              //  }}
              //  onMouseDown={handleMouseDown("awareness")}
              //  onMouseUp={resetPosition("awareness")}
              className="transform rotate-[30deg] hover:rotate-[0deg] bg-black text-white p-2 md:px-6 md:py-3 rounded-full transition-transform duration-900 shadow-md">
                Awareness
              </div>

              {/* Top-10 */}
              <div 
              //  draggable="true"
              //  style={{
              //    transform: `translate(${positions.top.x}px, ${positions.top.y}px) `,
              //    transition: draggingElement === "top" ? "none" : "transform 0.3s ease",
              //  }}
              //  onMouseDown={handleMouseDown("top")}
              //  onMouseUp={resetPosition("top")}
              className="transform rotate-[20deg] hover:rotate-[0deg] bg-black text-white p-2 md:px-6 md:py-3 rounded-full transition-transform duration-900 shadow-md">
                TOP-10
              </div>
            </div>

            {/* Other Static Grid Elements */}
            <div className="flex mb-4">
              <div className="grid -mx-6 justify-items-start items-end">
                <div 
                // draggable="true"
                // style={{
                //   transform: `translate(${positions.growth.x}px, ${positions.growth.y}px) `,
                //   transition: draggingElement === "growth" ? "none" : "transform 0.3s ease",
                // }}
                // onMouseDown={handleMouseDown("growth")}
                // onMouseUp={resetPosition("growth")}
                
                className="transform rotate-[80deg] hover:rotate-[360deg] transition-transform duration-700 flex justify-center items-center bg-black text-white rounded-full p-2 md:px-6 md:py-3 shadow-md">
                  <p className="text-center">Growth</p>
                </div>
              </div>
              <div className="grid -mr-6 justify-items-start items-end">
                <div 
                // draggable="true"
                // style={{
                //   transform: `translate(${positions.people.x}px, ${positions.people.y}px) `,
                //   transition: draggingElement === "people" ? "none" : "transform 0.3s ease",
                // }}
                // onMouseDown={handleMouseDown("people")}
                // onMouseUp={resetPosition("people")}
                
                className="transform rotate-[70deg] hover:rotate-[360deg] transition-transform duration-700 flex justify-center items-center bg-black text-white rounded-full p-2 md:px-6 md:py-3 shadow-md">
                  <p className="text-center">People</p>
                </div>
              </div>
              <div className="grid -mr-2 justify-items-start items-end">
                <div 
                // draggable="true"
                // style={{
                //   transform: `translate(${positions.symbol.x}px, ${positions.symbol.y}px) `,
                //   transition: draggingElement === "symbol" ? "none" : "transform 0.3s ease",
                // }}
                // onMouseDown={handleMouseDown("symbol")}
                // onMouseUp={resetPosition("symbol")}
                
                className="transform rotate-[70deg] hover:rotate-[360deg] transition-transform duration-700 flex justify-center items-center bg-black text-white rounded-full p-2 md:px-6 md:py-3 shadow-md">
                  <p className="text-center">Symbol</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interactive;
