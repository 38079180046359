import React from "react";
// import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import { Link } from "react-router-dom";

const GetHelp = () => {
  return (
    <>
      <div className="bg-transparent max-w-screen mx-auto md:px-64 p-8 py-3">
        <h1 className="text-[#1C65F0] text-[54px] text-center">
          Get help quickly
        </h1>
        <p className="text-[17px] text-[#9CA3BD] text-center">
          Feel free to reach out or explore our resources whenever you need
          quick
          <br /> support with your digital marketing needs.
        </p>
        <div className="md:flex  justify-between  gap-48 mt-8">
          <div>
            <p >
              <svg
              className="bg-[#1C65f0] text-white py-4 px-4 rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                height="80"
                width="80"
                viewBox="0 0 512 512"
              >
                <path fill="#FFF" d="M256 0c-25.3 0-47.2 14.7-57.6 36c-7-2.6-14.5-4-22.4-4c-35.3 0-64 28.7-64 64l0 165.5-2.7-2.7c-25-25-65.5-25-90.5 0s-25 65.5 0 90.5L106.5 437c48 48 113.1 75 181 75l8.5 0 8 0c1.5 0 3-.1 4.5-.4c91.7-6.2 165-79.4 171.1-171.1c.3-1.5 .4-3 .4-4.5l0-176c0-35.3-28.7-64-64-64c-5.5 0-10.9 .7-16 2l0-2c0-35.3-28.7-64-64-64c-7.9 0-15.4 1.4-22.4 4C303.2 14.7 281.3 0 256 0zM240 96.1l0-.1 0-32c0-8.8 7.2-16 16-16s16 7.2 16 16l0 31.9 0 .1 0 136c0 13.3 10.7 24 24 24s24-10.7 24-24l0-136c0 0 0 0 0-.1c0-8.8 7.2-16 16-16s16 7.2 16 16l0 55.9c0 0 0 .1 0 .1l0 80c0 13.3 10.7 24 24 24s24-10.7 24-24l0-71.9c0 0 0-.1 0-.1c0-8.8 7.2-16 16-16s16 7.2 16 16l0 172.9c-.1 .6-.1 1.3-.2 1.9c-3.4 69.7-59.3 125.6-129 129c-.6 0-1.3 .1-1.9 .2l-4.9 0-8.5 0c-55.2 0-108.1-21.9-147.1-60.9L52.7 315.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L119 336.4c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2L160 96c0-8.8 7.2-16 16-16c8.8 0 16 7.1 16 15.9L192 232c0 13.3 10.7 24 24 24s24-10.7 24-24l0-135.9z" />
              </svg>{" "}
            </p>
            <p className="text-[#FFFCF5] text-[44px]">Read the docs</p>
            <p className="text-[17px] text-[#9CA3BD]">
              Looking for detailed information about our digital marketing
              solutions and how they can benefit your business? Our
              comprehensive resources are here to guide you through the process
              and answer all your questions.
            </p>

            <button className="bg-blue-700 w-full mt-8 text-white py-2 px-6 rounded-full hover:bg-blue-800">
              <Link to="/contact">Read Documentation</Link>
            </button>
          </div>
          <div className="mt-4 md:mt-0">
          <p >
              <svg
              className="bg-[#1C65f0] text-white py-4 px-4 rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                height="80"
                width="80"
                viewBox="0 0 512 512"
              >
                <path fill="#FFF" d="M256 0c-25.3 0-47.2 14.7-57.6 36c-7-2.6-14.5-4-22.4-4c-35.3 0-64 28.7-64 64l0 165.5-2.7-2.7c-25-25-65.5-25-90.5 0s-25 65.5 0 90.5L106.5 437c48 48 113.1 75 181 75l8.5 0 8 0c1.5 0 3-.1 4.5-.4c91.7-6.2 165-79.4 171.1-171.1c.3-1.5 .4-3 .4-4.5l0-176c0-35.3-28.7-64-64-64c-5.5 0-10.9 .7-16 2l0-2c0-35.3-28.7-64-64-64c-7.9 0-15.4 1.4-22.4 4C303.2 14.7 281.3 0 256 0zM240 96.1l0-.1 0-32c0-8.8 7.2-16 16-16s16 7.2 16 16l0 31.9 0 .1 0 136c0 13.3 10.7 24 24 24s24-10.7 24-24l0-136c0 0 0 0 0-.1c0-8.8 7.2-16 16-16s16 7.2 16 16l0 55.9c0 0 0 .1 0 .1l0 80c0 13.3 10.7 24 24 24s24-10.7 24-24l0-71.9c0 0 0-.1 0-.1c0-8.8 7.2-16 16-16s16 7.2 16 16l0 172.9c-.1 .6-.1 1.3-.2 1.9c-3.4 69.7-59.3 125.6-129 129c-.6 0-1.3 .1-1.9 .2l-4.9 0-8.5 0c-55.2 0-108.1-21.9-147.1-60.9L52.7 315.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L119 336.4c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2L160 96c0-8.8 7.2-16 16-16c8.8 0 16 7.1 16 15.9L192 232c0 13.3 10.7 24 24 24s24-10.7 24-24l0-135.9z" />
              </svg>{" "}
            </p>
            <p className="text-[#FFFCF5] text-[44px]">Submit a Ticket</p>
            <p className="text-[17px] text-[#9CA3BD]">
            If you need immediate assistance or have a specific query, our support system is the best way to reach our expert team. Submit a request, and we’ll prioritize it to provide you with prompt solutions.
            </p>

            <button className="bg-blue-700 w-full mt-8 text-white py-2 px-6 rounded-full hover:bg-blue-800">
              <Link to="/contact">Read Documentation</Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetHelp;
