import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";

const Stats = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className=" text-white py-12 flex flex-col items-center justify-center px-4">
      {/* Heading */}
      <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-center">
        Numbers speaking for themselves
      </h2>

      {/* Stats Section */}
      <div className="flex flex-wrap justify-center items-center gap-6 w-full max-w-4xl">
        {/* Single Stat */}
        <div className="text-center">
          <h3
            className={`text-3xl sm:text-4xl md:text-6xl font-bold text-blue-500 ${
              isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
            } transition-all ease-in duration-300`}>
            {isVisible && <CountUp start={65} end={75} duration={1} />}%
          </h3>
          <p className="text-gray-400 mt-2 text-lg">Increase ROI rate</p>
        </div>

        <div className="w-[1px] h-16 bg-gray-600 hidden md:block"></div>

        <div className="text-center">
          <h3
            className={`text-3xl sm:text-4xl md:text-6xl font-bold text-blue-500 ${
              isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
            } transition-all ease-in duration-300`}>
            {isVisible && <CountUp start={3910} end={4000} duration={1} />}+
          </h3>
          <p className="text-gray-400 mt-2 text-lg">Clients</p>
        </div>

        <div className="w-[1px] h-16 bg-gray-600 hidden md:block"></div>

        <div className="text-center">
          <h3
            className={`text-3xl sm:text-4xl md:text-6xl font-bold text-blue-500 ${
              isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
            } transition-all ease-in duration-300`}>
            {isVisible && <CountUp start={40} end={50} duration={1} />}+
          </h3>
          <p className="text-gray-400 mt-2 text-lg">Operating countries</p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
