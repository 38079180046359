import React from "react";
import { FaArrowUpLong } from "react-icons/fa6";

const UpArrow = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className="fixed bottom-4 right-4 bg-[#f8f8f1] p-3 rounded-full shadow-lg hover:scale-105 transition duration-300">
      <FaArrowUpLong size={20} color="#4a5458" />
    </button>
  );
};

export default UpArrow;
