import React, { useRef, useState, useEffect } from "react";
import slider from "../assets/tech.svg";
import ad from "../assets/ad.svg";
import bd from "../assets/bd.svg";
import content from "../assets/content.svg";
import mr from "../assets/mr.svg";
import seo from "../assets/seo.svg";
import smd from "../assets/smd.svg";
import video from "../assets/video.svg";
import wd from "../assets/wd.svg";
import { Link } from "react-router-dom";

const Card = ({ title, description, icon }) => {
  return (
    <div className="bg-gradient-to-br from-[#1a5fe4] to-[#020639] text-white rounded-3xl p-6 shadow-lg flex flex-col w-[95%] sm:w-[calc(50%-1rem)] lg:w-1/3 h-auto shrink-0 gap-6">
      <div className="flex justify-between items-start gap-4">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-medium leading-tight">
          {title}
        </h2>
        <img src={icon} alt={title} className="w-16 h-16 sm:w-20 sm:h-20" />
      </div>
      <p className="text-sm sm:text-base lg:text-lg leading-relaxed">
        {description}
      </p>
    </div>
  );
};

const Cards = () => {
  const sliderRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [scrollSpeed, setScrollSpeed] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);


  const cardData = [
    {
      title: "Advertising Campaigns",
      description:
        "We create effective advertising campaigns across multiple platforms to reach potential customers at the best cost. Our ongoing analysis ensures improved results and informed decisions through regular performance reports.",
      icon: ad,
    },
    {
      title: "Content Creation",
      description:
        "Content is key to marketing success. Our team creates engaging content—articles, blogs, videos, and social media posts—that grabs attention and fosters audience interaction.",
      icon: content,
    },
    {
      title: "Market Research",
      description:
        "We perform detailed market analysis to understand customer behaviors, identify opportunities, and improve digital strategies. This enhances audience targeting and maximizes ROI.",
      icon: mr,
    },
    {
      title: "Brand Development",
      description:
        "We identify and resolve brand challenges through data analysis, helping you reach your marketing goals and strengthening your brand’s market presence.",
      icon: bd,
    },
    {
      title: "Video and Motion Graphics",
      description:
        "Our team produces creative, high-quality videos and motion graphics that captivate your audience and highlight your products or services innovatively.",
      icon: video,
    },
    {
      title: "Social Media Design",
      description:
        "We design visually engaging social media content, from logo creation to ongoing post design, to capture attention and enhance brand visibility.",
      icon: smd,
    },
    {
      title: "SEO Services",
      description:
        "We optimize your site through keyword analysis, search engine alignment, and technical improvements to boost visibility and ensure compatibility across all devices.",
      icon: seo,
    },
    {
      title: "Website Design",
      description:
        "We create responsive, modern websites with seamless UI/UX, ensuring a consistent, engaging user experience across devices that tells your brand’s story.",
      icon: wd,
    },
  ];

  const smoothScroll = () => {
    if (sliderRef.current && scrollSpeed !== 0) {
      sliderRef.current.scrollLeft += scrollSpeed / 60;
      animationFrameRef.current = requestAnimationFrame(smoothScroll);
    }
  };

  useEffect(() => {
    if (isHovered && scrollSpeed !== 0) {
      animationFrameRef.current = requestAnimationFrame(smoothScroll);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
    }
    return () => cancelAnimationFrame(animationFrameRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, scrollSpeed]);

  const handleMouseMove = e => {
    if (sliderRef.current) {
      const { left, width } = sliderRef.current.getBoundingClientRect();
      const x = e.clientX - left;

      const center = width / 2;
      const distanceFromCenter = x - center;

      const maxSpeed = 600;
      const newScrollSpeed = (distanceFromCenter / center) * maxSpeed;

      setScrollSpeed(newScrollSpeed);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setScrollSpeed(0);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);



  // const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });


  // const handleMouseMove2 = (e) => {
  //   setCursorPosition({ x: e.clientX, y: e.clientY });
  // };

  return (
    <div >
      {/* <div
          className="fixed pointer-events-none transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: `${cursorPosition.x}px`,
            top: `${cursorPosition.y}px`,
          
          }}
        >
          <div className="bg-white/25 backdrop-blur-sm  text-white rounded-full p-4 shadow-md">
           <p>Slider</p>
            {/* Replace with desired icon 
          </div>
        </div>*/}
     <div 
      ref={sectionRef}
      className="text-white flex flex-col items-center justify-center px-4 mb-20">
      <h2
        className={`text-2xl sm:text-4xl text-center font-medium py-4 md:py-6 lg:text-6xl ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200`}>
        What Drives Your Digital Success
      </h2>
      <div
        ref={sliderRef}
        style={{ cursor: `url(${slider}), auto` }}
        className={`flex overflow-x-scroll no-scrollbar space-x-4 w-full px-4 py-6 relative md:px-8  ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200 delay-100`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}>
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
          />
        ))}
      </div>
      <Link to="/services">
        <button
          className={`rounded-full bg-gradient-to-r from-[#1a5fe4] to-[#020639] hover:text-blue-700 hover:from-[#FFF] hover:to-[#FFF] text-white px-6 py-2 sm:px-8 sm:py-3 text-sm sm:text-lg mt-3 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in`}>
          More Services
        </button>
      </Link>
    </div>
    </div>
   
  );
};

export default Cards;
