import React from "react";
import { motion } from "motion/react";

const GradientCircleAnimation = ({
  color1 = "#1C65F0",
  color2 = "#0442BB",
  color3 = "#05918E",
  color4 = "#8a4af3",
  blur = 50,
  duration = 6,
  colorDuration = 2,
  numberOfCircles = 4,
  size = 400,
}) => {
  // Define the positions for the circles to start
  const positions = [
    { x: "5%", y: "-30%" }, // Left circle
    { x: "20%", y: "-15%" }, // Left circle
    { x: "45%", y: "-40%" }, // Center circle
    { x: "50%", y: "-25%" }, // Right circle
  ];

  // Adjust the circle size based on the user input
  const circleSize = `${size}px`; // Dynamic size control

  // Predefined colors
  const hexColors = [
    "#1C65F0",
    "#0442BB",
    "#05918E",
    "#6ACF31",
    "#6CCDB9",
    "#741BB3",
    "#B6E233",
    "#EC2D8A",
    "#F6A629",
    "#FA0929",
    "#FF630C",
    "#FFD50D",
  ];

  // Initial colors for circles from props
  const initialColors = [color1, color2, color3, color4];

  // Function to generate a random sequence of colors
  const getRandomColors = count => {
    return Array.from({ length: count }, () => {
      const randomIndex = Math.floor(Math.random() * hexColors.length);
      return hexColors[randomIndex];
    });
  };

  // Create the circles based on the number of circles selected
  const circles = Array.from({ length: numberOfCircles }, (_, index) => {
    const position = positions[index % positions.length]; // Cycle through the positions
    const randomColors = getRandomColors(6); // Get 6 random colors for this circle
    const startingColor =
      initialColors[index % initialColors.length] || randomColors[0];

    return (
      <motion.div
        key={index}
        style={{
          position: "absolute",
          width: circleSize,
          height: circleSize,
          borderRadius: "50%",
          backgroundColor: startingColor, // Initial color
          filter: `blur(${blur}px)`, // Blur effect control
          transform: "translate(-50%, -50%)",
          left: position.x,
          top: position.y,
          zIndex: -1, // Ensure circles are behind content
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 0.5,
          x: [
            `${position.x}`,
            `${Math.random() * 100 - 50}%`,
            `${Math.random() * 100 - 50}%`,
            `${position.x}`,
          ],
          y: [
            `${position.y}`,
            `${Math.random() * 100 - 50}%`,
            `${Math.random() * 100 - 50}%`,
            `${position.y}`,
          ],
          backgroundColor: [startingColor, ...randomColors], // Complete random color sequence
        }}
        transition={{
          opacity: { duration: 1 },
          x: {
            repeat: Infinity,
            duration: duration,
            repeatType: "loop",
            ease: "easeInOut",
          },
          y: {
            repeat: Infinity,
            duration: duration,
            repeatType: "loop",
            ease: "easeInOut",
          },
          backgroundColor: {
            repeat: Infinity,
            duration: randomColors.length * colorDuration, // Complete all color transitions
            repeatType: "loop",
            ease: "easeInOut",
            times: randomColors.map((_, i) => i / (randomColors.length - 1)), // Even distribution
          },
        }}
      />
    );
  });

  return (
    <div className="max-w-screen-lg"
    //   style={{
    //     // position: "relative",
    //     // width: "100%",
    //     // height: "50vh",
    //           // overflow: "clip", // Allow circles to move outside container bounds
    //             // position: "relative",
    //             width: "100%",
    //             height: "100vh",
    //             overflow: "visible",
    //             zindex:"-1",
    //   }}
      >
      {circles}
    </div>
  );
};

export default GradientCircleAnimation;