import React, { useState } from "react";
import { Link } from "react-router-dom";

import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const Discover = () => {
  const [hover, setHover] = useState(false);
  return (
    <div className="bg-gradient-to-b from-[#1e40af] to-black py-12 ">
      <div className="max-w-7xl mx-auto px-4">
        <div className="bg-white rounded-2xl shadow-lg p-10 md:flex items-center justify-between">
          <div>
            <h2 className="text-3xl font-bold text-blue-900 mb-2 md:w-[80%]">
              Make a Lasting Digital Impact with Cypera
            </h2>
            <p className="text-gray-700 w-[80%]">
              Discover the art of going beyond the surface. Cypera ensures that
              your digital assets are crafted to make a real difference in a
              world of digital marketing that evolves by the moment.
            </p>
          </div>
          <div className=" flex flex-row items-center">
          <Link
            to="/contact"
            onMouseEnter={() => setHover(false)} // Set hover to true
            onMouseLeave={() => setHover(true)}
            className="p-3 bg-blue-500 hover:bg-white border border-[#1C65f0] hover:text-[#1C65f0] text-white  text-base font-semibold rounded-2xl"
          >
            {hover ? <GoArrowDownRight size='25' /> : <GoArrowUpRight size='25'/>}{" "}
          </Link>
            <button className="py-3 px-6 text-nowrap  bg-gradient-to-r hover:from-[#FFF] hover:to-[#FFF] border border-[#1C65f0] hover:text-[#1C65f0] text-white from-[#020639] to-[#1a5fe4] text-base font-semibold rounded-2xl">
              Talk to our Expert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
