import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import Faq from "./Faq";
import Testimonials from "./Testimonials";
import Discover from "./Discover";
import ad from "../assets/ad.svg";
import bd from "../assets/bd.svg";
import content from "../assets/content.svg";
import mr from "../assets/mr.svg";
import seo from "../assets/seo.svg";
import smd from "../assets/smd.svg";
import video from "../assets/video.svg";
import wd from "../assets/wd.svg";
// import { Link } from "react-router-dom";

const Card = ({ title, description, icon }) => {
  return (
    <div className="bg-gradient-to-br from-[#1a5fe4] to-[#020639] text-white rounded-3xl p-6 shadow-lg flex flex-col w-full h-auto md:h-80 shrink-0 gap-6 md:gap-10">
      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center h-auto md:h-20">
        <h2 className="text-2xl md:text-3xl lg:text-4xl w-full md:w-[75%] font-medium mb-4 md:mb-0 leading-snug md:leading-tight">
          {title}
        </h2>
        {/* Icon Section */}
        <img
          src={icon}
          alt={title}
          className="w-16 h-16 md:w-20 md:h-20 self-center md:self-end"
        />
      </div>
      <p className="text-base md:text-lg leading-relaxed">{description}</p>
    </div>
  );
};

const Services = () => {
   const [isVisible, setIsVisible] = useState(false);
   const sectionRef = useRef(null);

   useEffect(() => {
     const observer = new IntersectionObserver(
       ([entry]) => {
         if (entry.isIntersecting) {
           setIsVisible(true);
         }
       },
       { threshold: 0.5 } // Trigger when 50% of the component is visible
     );

     const currentRef = sectionRef.current;
     if (currentRef) {
       observer.observe(currentRef);
     }

     return () => {
       if (currentRef) {
         observer.unobserve(currentRef);
       }
     };
   }, []);
  
   const cardData = [
     {
       title: "Advertising Campaigns",
       description:
         "We create effective advertising campaigns across multiple platforms to reach potential customers at the best cost. Our ongoing analysis ensures improved results and informed decisions through regular performance reports.",
       icon: ad,
     },
     {
       title: "Content Creation",
       description:
         "Content is key to marketing success. Our team creates engaging content—articles, blogs, videos, and social media posts—that grabs attention and fosters audience interaction.",
       icon: content,
     },
     {
       title: "Market Research",
       description:
         "We perform detailed market analysis to understand customer behaviors, identify opportunities, and improve digital strategies. This enhances audience targeting and maximizes ROI.",
       icon: mr,
     },
     {
       title: "Brand Development",
       description:
         "We identify and resolve brand challenges through data analysis, helping you reach your marketing goals and strengthening your brand’s market presence.",
       icon: bd,
     },
     {
       title: "Video and Motion Graphics",
       description:
         "Our team produces creative, high-quality videos and motion graphics that captivate your audience and highlight your products or services innovatively.",
       icon: video,
     },
     {
       title: "Social Media Design",
       description:
         "We design visually engaging social media content, from logo creation to ongoing post design, to capture attention and enhance brand visibility.",
       icon: smd,
     },
     {
       title: "SEO Services",
       description:
         "We optimize your site through keyword analysis, search engine alignment, and technical improvements to boost visibility and ensure compatibility across all devices.",
       icon: seo,
     },
     {
       title: "Website Design",
       description:
         "We create responsive, modern websites with seamless UI/UX, ensuring a consistent, engaging user experience across devices that tells your brand’s story.",
       icon: wd,
     },
  ];
  
  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Our service boosts your digital presence
          </h1>
          <p
            className={`text-lg max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            We specialize in delivering a wide range of digital services that
            drive growth and innovation. Our expertise spans from strategic
            marketing to seamless web development, ensuring every solution is
            crafted to meet the unique needs of your business for maximum
            impact.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl mx-auto my-8 px-4">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
          />
        ))}
      </div>

      <Faq />
      
      <Testimonials />

      <Discover />
    </>
  );
};

export default Services;
