import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import UpArrow from "./components/UpArrow";
import Homeindex from "./components/Homeindex";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import BlogsPage from "./components/BlogsPage";
import CaseStudies from "./components/CaseStudies";
import Career from "./components/Career";
import ScrollToTop from "./components/ScrollToTop";
import NotFoundPage from "./components/Page404";
import { useEffect, useState } from "react";

function App() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Update cursor position on mouse move
  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <>
      <div>
        {/* Custom Cursor */}
        <div
          className="fixed pointer-events-none transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: `${cursorPosition.x}px`,
            top: `${cursorPosition.y}px`,
            zIndex: 100,
          }}>
          <div className="bg-white/25 backdrop-blur-sm  text-white rounded-full p-3 shadow-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="8"
              width="8"
              viewBox="0 0 512 512">
              <path
                fill="#1c65f0"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"
              />
            </svg>
            {/* Replace with desired icon */}
          </div>
        </div>
      </div>
      <ScrollToTop />
      <header className="sticky top-2 mx-2 z-50">
        <Navbar />
      </header>

      <div className="App">
        <Routes>
          <Route path="/" element={<Homeindex />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/career" element={<Career />}></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </div>

      <footer className="bg-black">
        <Footer />
      </footer>
      <UpArrow />
    </>
  );
}

export default App;
