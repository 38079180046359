import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./GradientCircles";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import { PiStarFourFill } from "react-icons/pi";
// import star from "../../assets/Start_1.svg";
// import { PiStarFourFill } from "react-icons/pi";

const HeroBanner = () => {
  const [hover, setHover] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the component is visible
      );

      const currentRef = sectionRef.current;
      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);


  return (
    <>
      <div
        ref={sectionRef}
        className="relative text-white text-center pt-20 pb-16 px-4 max-w-6xl mx-auto">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <motion.div
          className="absolute top-5 left-10 md:top-20 md:left-40 bg-[#00002C] text-white px-3 py-1 rounded-full text-sm"
          animate={{
            y: [0, -8, 0],
            x: [0, 12, 0],
            rotate: [-20, -20, -20], // Added rotation for tilt effect
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            repeatType: "loop",
            ease: "easeInOut",
          }}>
          Ads Production
        </motion.div>
        <motion.div
          className="absolute top-10 right-5 md:top-14 md:right-32 bg-white text-[#EC2D8A] px-3 py-1 rounded-full text-sm"
          animate={{
            y: [0, -10, 0],
            x: [0, 10, 0],
            rotate: [30, 30, 30],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            repeatType: "loop",
            ease: "easeInOut",
          }}>
          Video Marketing
        </motion.div>
        <div
          className={`inline-block bg-transparent border border-white text-black px-2 py-1.5 rounded-full mb-6 md:px-6 md:py-3 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200`}>
          <span className="text-xs md:text-sm font-bold bg-white rounded-2xl px-2 py-1 mr-2 text-[#1C65F0]">
            New
          </span>{" "}
          <span className="text-white">Unleash Your </span>
          <strong className="text-white text-xs md:text-sm">
            Potential and Creativity
          </strong>
        </div>
        <h1
          className={`text-5xl md:text-7xl max-w-6xl tracking-tighter font-medium mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-100`}>
          We Focus On Growing Your Brand Online
        </h1>
        <p
          className={`text-lg max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-150`}>
          Transform your business with our 360-degree analysis, identifying
          hidden opportunities to enhance your strategy and drive sales. Let us
          optimize your digital presence for maximum impact and growth.
        </p>
        <div
          className={`flex bg-transparent items-center justify-center space-x-2 bg-black p-4 rounded-xl ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-200`}>
          <div className="flex space-x-[-12px]">
            <img
              src="https://via.placeholder.com/50"
              alt="Trusted by 12k+"
              className="w-10 h-10 rounded-full border-2 border-white transform transition-transform duration-300 hover:scale-110 hover:-translate-y-2"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="Trusted by 12k+"
              className="w-10 h-10 rounded-full border-2 border-white transform transition-transform duration-300 hover:scale-110 hover:-translate-y-2"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="Trusted by 12k+"
              className="w-10 h-10 rounded-full border-2 border-white transform transition-transform duration-300 hover:scale-110 hover:-translate-y-2"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="Trusted by 12k+"
              className="w-10 h-10 rounded-full border-2 border-white transform transition-transform duration-300 hover:scale-110 hover:-translate-y-2"
            />
          </div>

          <span>Trusted already by 12k+</span>
        </div>
        <div
          className={`flex items-center justify-center ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-200`}>
          <Link
            to="/contact"
            onMouseEnter={() => setHover(false)} // Set hover to true
            onMouseLeave={() => setHover(true)}
            className="p-3 bg-blue-500 hover:bg-white border border-[#1C65f0] hover:text-[#1C65f0] text-white  text-base font-semibold rounded-2xl">
            {hover ? (
              <GoArrowDownRight size="25" />
            ) : (
              <GoArrowUpRight size="25" />
            )}{" "}
          </Link>
          <button className="bg-gradient-to-r from-[#1a5fe4] to-[#020639] text-white hover:text-blue-700 font-bold py-3 px-6 rounded-2xl transition-all ease-in-out duration-300 hover:from-[#FFF] hover:to-[#FFF]">
            <Link to="/contact">Talk to our expert</Link>
          </button>
        </div>
      </div>

           <div
        class={`relative mb-10 flex overflow-x-hidden text-[#1c65f0] bg-white font-kanit ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200 delay-250 font-kanit`}>
        <div class="py-2 animate-marquee whitespace-nowrap ">

          <span class="text-lg mx-4">Social Media Management</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Content Creation</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Graphic Design</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Digital Marketing</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Mobile App Design</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">E-commerce Solutions</span>
          {/* <PiStarFourFill className="inline-block" />/ */}
          <span class="text-lg mx-4">SEO Optimization</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">UX/UI Design</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Web Design</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Consulting Services</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Video Production</span>
          {/* <PiStarFourFill className="inline-block" /> */}
          <span class="text-lg mx-4">Branding</span>
        </div>

        <div class="absolute top-0 py-2 animate-marquee2 whitespace-nowrap">
          <span class="text-lg mx-4">Social Media Management</span>
          <span class="text-lg mx-4">Content Creation</span>
          <span class="text-lg mx-4">Graphic Design</span>
          <span class="text-lg mx-4">Digital Marketing</span>
          <span class="text-lg mx-4">Mobile App Design</span>
          <span class="text-lg mx-4">E-commerce Solutions</span>
          <span class="text-lg mx-4">SEO Optimization</span>
          <span class="text-lg mx-4">UX/UI Design</span>
          <span class="text-lg mx-4">Web Design</span>
          <span class="text-lg mx-4">Consulting Services</span>
          <span class="text-lg mx-4">Video Production</span>
          <span class="text-lg mx-4">Branding</span>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
