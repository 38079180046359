import React from "react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

const Map = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between text-white p-6 md:p-8 gap-6 md:gap-8 h-auto md:h-screen">
      {/* Contact Info Section */}
      <div className="flex flex-col gap-6 w-full md:w-1/2">
        <h2 className="text-3xl md:text-5xl lg:text-6xl font-medium leading-tight">
          Ready to bring your ideas to life?
        </h2>
        <p className="text-gray-400 text-base md:text-lg lg:text-xl leading-relaxed">
          Reach out to us for expert insights, innovative solutions, and
          seamless support. Whether you have a question, project, or just want
          to say hello, we're here to help you every step of the way. Let's
          start a conversation that makes an impact.
        </p>
        <div className="flex flex-col gap-4">
          <div className="flex items-center space-x-4 bg-gray-800/70 p-4 rounded-2xl border border-gray-400">
            <FaPhoneAlt
              className="rounded-xl bg-white p-2"
              size={40}
              color="#1c65f0"
            />
            <span className="text-lg md:text-2xl lg:text-3xl pl-4">
              +91 8909436659
            </span>
          </div>
          <div className="flex items-center space-x-4 bg-gray-800/70 p-4 rounded-2xl border border-gray-400">
            <FaEnvelope
              className="rounded-xl bg-white p-2"
              size={40}
              color="#1c65f0"
            />
            <span className="text-lg md:text-2xl lg:text-3xl pl-4">
              help@cyperadigital.com
            </span>
          </div>
        </div>
      </div>
      {/* Map Section */}
      <div className="w-full md:w-1/2 flex flex-col">
        {/* <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62208.430523445255!2d77.730643!3d12.97013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13734e00df9f%3A0x6f7f75e99fbcf481!2sCronbay%20Technologies!5e0!3m2!1sen!2sin!4v1724233069022!5m2!1sen!2sin"
          className="w-full h-60 sm:h-80 md:h-96 lg:h-[400px] rounded-2xl shadow-md"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe 
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d28899.961080494537!2d55.381564!3d25.118946!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDA3JzA4LjQiTiA1NcKwMjInNDAuMyJF!5e0!3m2!1sen!2sus!4v1733908952911!5m2!1sen!2sus"
           className="w-full h-60 sm:h-80 md:h-96 lg:h-[400px] rounded-2xl shadow-md"
           allowFullScreen=""
           loading="lazy"
           referrerPolicy="no-referrer-when-downgrade"></iframe>
        <p className="text-gray-200 text-sm md:text-lg lg:text-xl my-4 flex items-center gap-3 align-center">
          <IoLocationSharp
            size={24}
            className="min-w-[40px] min-h-[40px] text-blue-500"
          />
          001 - 39332,IFZA Business Park, Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates
        </p>
      </div>
    </div>
  );
};

export default Map;
